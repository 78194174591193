import { Paths } from '../../_constants';
import { useCart } from '../../nanostore/_useCart';

const OrderOrCart = () => {
    const { cart } = useCart();

    return (
        <a
            href={cart?.length ? Paths.cart : Paths.schedule}
            className="flex items-center text-sm leading-4 text-inherit 2xl:text-base"
            itemProp="url"
            rel="prefetch"
        >
            <div className="relative flex items-center gap-1">
                <span>{cart?.length ? 'Cart' : 'Order'}</span>

                <div className="flex-row items-center justify-center min-[345px]:flex">
                    {cart?.length ? (
                        <svg className="size-5 fill-inherit" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18.1853 6.1925C18.1087 6.08173 18.0063 5.99119 17.887 5.92864C17.7677 5.86608 17.635 5.83338 17.5003 5.83334H6.11116L5.14949 3.525C5.0234 3.22102 4.80988 2.96133 4.53601 2.77885C4.26214 2.59636 3.94025 2.49932 3.61116 2.5H1.66699V4.16667H3.61116L7.56449 13.6542C7.62781 13.806 7.73464 13.9356 7.87151 14.0268C8.00839 14.118 8.16919 14.1667 8.33366 14.1667H15.0003C15.3478 14.1667 15.6587 13.9508 15.7812 13.6267L18.2812 6.96C18.3284 6.83385 18.3444 6.69813 18.3277 6.56446C18.311 6.43079 18.2621 6.30316 18.1853 6.1925V6.1925ZM14.4228 12.5H8.88949L6.80616 7.5H16.2978L14.4228 12.5Z"
                                fill="#B0B0B0"
                            />
                            <path
                                d="M8.75 17.5C9.44036 17.5 10 16.9404 10 16.25C10 15.5596 9.44036 15 8.75 15C8.05964 15 7.5 15.5596 7.5 16.25C7.5 16.9404 8.05964 17.5 8.75 17.5Z"
                                fill="#B0B0B0"
                            />
                            <path
                                d="M14.583 17.5C15.2734 17.5 15.833 16.9404 15.833 16.25C15.833 15.5596 15.2734 15 14.583 15C13.8927 15 13.333 15.5596 13.333 16.25C13.333 16.9404 13.8927 17.5 14.583 17.5Z"
                                fill="#B0B0B0"
                            />
                        </svg>
                    ) : (
                        <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            width="512.000000pt"
                            height="512.000000pt"
                            viewBox="0 0 512.000000 512.000000"
                            preserveAspectRatio="xMidYMid meet"
                            fill="currentColor"
                            className="size-4 fill-inherit"
                        >
                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                                <path
                                    d="M552 4643 c-18 -9 -41 -27 -52 -40 -20 -26 -250 -706 -250 -739 0
    -10 9 -38 20 -61 22 -49 70 -83 116 -83 l31 0 6 -107 c3 -58 4 -110 1 -115 -3
    -4 -76 -8 -163 -8 -148 0 -160 -1 -192 -23 -94 -63 -88 -193 12 -252 29 -17
    56 -20 197 -23 115 -3 162 -7 162 -15 0 -32 70 -1891 73 -1947 5 -78 25 -121
    70 -149 30 -18 50 -21 152 -21 l117 0 -6 -27 c-37 -167 2 -325 108 -435 96
    -101 201 -142 346 -136 81 3 105 8 163 35 88 42 178 127 217 207 49 100 59
    199 34 324 l-6 32 852 0 852 0 -6 -28 c-39 -177 4 -332 123 -446 36 -34 87
    -69 128 -89 58 -27 82 -32 163 -35 145 -6 250 35 346 136 106 110 145 268 108
    434 l-6 28 118 0 c100 0 124 3 151 20 44 26 65 73 70 150 3 56 73 1915 73
    1947 0 8 47 12 163 15 140 3 167 6 196 23 100 59 106 189 12 252 -32 22 -44
    23 -192 23 -87 0 -160 4 -163 8 -3 5 -2 57 1 115 l6 107 30 0 c63 0 136 77
    137 144 0 33 -230 713 -250 739 -11 13 -35 32 -54 41 -31 15 -205 16 -2008 16
    -1799 0 -1976 -2 -2006 -17z m3900 -453 l56 -170 -1948 0 -1948 0 56 170 57
    170 1835 0 1835 0 57 -170z m-3322 -1650 l0 -1180 -159 0 -159 0 -6 93 c-3 50
    -22 524 -41 1052 -19 528 -38 1017 -41 1088 l-7 127 207 0 206 0 0 -1180z
    m2560 0 l0 -1180 -1130 0 -1130 0 0 1180 0 1180 1130 0 1130 0 0 -1180z m706
    1053 c-3 -71 -22 -560 -41 -1088 -19 -528 -37 -1002 -41 -1052 l-6 -93 -159 0
    -159 0 0 1180 0 1180 206 0 207 0 -7 -127z m-3035 -2566 c89 -59 89 -185 0
    -244 -71 -48 -175 -20 -213 57 -50 98 21 210 132 210 32 0 58 -7 81 -23z
    m2560 0 c39 -26 69 -79 69 -122 0 -43 -30 -96 -69 -122 -71 -48 -175 -20 -213
    57 -50 98 21 210 132 210 32 0 58 -7 81 -23z"
                                ></path>
                            </g>
                        </svg>
                    )}
                    {cart?.length ? <div className="absolute -right-2 -top-1.5 text-sm">{cart.length}</div> : null}
                </div>
            </div>
        </a>
    );
};

export default OrderOrCart;
